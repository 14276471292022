
let ThemeDetail = null;

ThemeDetail = {
  themeColor: "#42a16a",
  logo: null,
  image: null,
  icon: "SATfavicon.ico",
  name: "TestAdmit",
  tabName: "MOCK100",
  examName: "MOCK100",
  email: "support@MOCK100.com",
  linkArr: []
};

export default ThemeDetail;
