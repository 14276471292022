import moment from "moment";
export class MoneyCalculator{
    static addThousandsComma(e){
        console.log(e);
        
        return e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}


export class instantInstance{
    formatDate(e) {
        return moment(e).format("YYYY-MM-DD")
      };
      formatDateTime(e) {
          return moment(e).format("YYYY-MM-DD HH:mm")
      };
      formatTime(e) {
          return moment(e, "HH:mm:ss").format("LT")
      };
      formatGoogleTime(e) {
        const t = moment()
          , n = moment(e)
          , r = t.diff(n, "hours");
        if (r < 0)
            return moment(e).format("YYYY/MM/DD");
        if (r < 24)
            return moment(e).format("HH:mm");
        const a = t.diff(n, "years")
          , o = t.format("MM/DD")
          , s = n.format("MM/DD");
        return a < 1 && o !== s ? moment(e).format("M/DD") : moment(e).format("YYYY/M/DD")
    };
    formatSecondToMMSS(e) {
        return `${String(Math.floor(e / 60)).padStart(2, "0")}:${String(e % 60).padStart(2, "0")}`
    }
}
export const instant = new instantInstance ();
export class user{
    static displayName(first_name,last_name,n=''){
        console.log(first_name);
        if (!first_name && last_name)
            return last_name;
        if (first_name && !last_name)
            return first_name;
        if (!first_name || !last_name)
            return "-";
        const r = first_name=>/.*[\u4e00-\u9fa5]+.*$/.test(first_name);
        let i = "";
        return i = r(first_name) || r(last_name) ? `${last_name}${first_name}` : `${first_name} ${last_name}`,
        i = n ? `${i} (${n})` : i,
        i
    }
    
}
