import ApiService from "@/common/api.service";

export default {
  getAnalytics(payload) {
    return ApiService.query("/user/user_exams/analysis", payload);
  },
  getTodayAnalytics() {
    return ApiService.query("/user/user_exams/analysis/today");
  },
  getTransactionAnalytics(payload) {
    return ApiService.query("transaction-logs/analysis", payload);
  },
  getTodayTransactionAnalytics() {
    return ApiService.query("transaction-logs/analysis/today");
  },
  getOrderAnalytics(payload) {
    return ApiService.query("order/analysis", payload);
  },
  getTodayOrderAnalytics() {
    return ApiService.query("order/analysis/today");
  },
  getSubsAnalytics(payload) {
    return ApiService.query("subscription-analyses", payload);
  }
};
