import ApiService from "@/common/api.service";

export default {
  fetchVocabBooks() {
    return ApiService.get("vocab/books");
  },
  fetchVocabBook(vocabBookId, page) {
    return ApiService.query(`vocab/book/id/${vocabBookId}/lists`, { page });
  },
  fetchVocabList(vocabListId) {
    return ApiService.query("vocab/backs", { vocab_lists: [vocabListId] });
  },
  fetchVocabulary(vocabularyId) {
    return ApiService.get(`vocab/index/id/${vocabularyId}`);
  },
  addVocabMemory(vocabularyId, memory, is_public) {
    return ApiService.post(`vocab/memory/vocab_id/${vocabularyId}`, {
      memory,
      is_public
    });
  },
  updateVocabMemory(vocabMemoryId, memory, is_public) {
    return ApiService.patch(`vocab/memory/vocab_id/${vocabMemoryId}`, {
      memory,
      is_public
    });
  },
  deleteVocabMemory(vocabMemoryId) {
    return ApiService.delete(`vocab/memory/${vocabMemoryId}`);
  },
  likeVocabMemory(vocabMemoryId) {
    return ApiService.post(`vocab/memory/like/${vocabMemoryId}`);
  },
  fetchVocabCollections(page) {
    return ApiService.query("vocab/collections", { page });
  },
  fetchCollectionVocabularies(collectionId) {
    return ApiService.get(`vocab/collection_vocabs/id/${collectionId}/vocabs`);
  },
  createVocabCollection(title, desc) {
    return ApiService.post("vocab/collection", { title, desc });
  },
  updateVocabCollection(collectionId, title, desc) {
    return ApiService.patch(`vocab/collection/${collectionId}`, {
      title,
      desc
    });
  },
  deleteVocabCollection(collectionId) {
    return ApiService.delete(`vocab/collection/${collectionId}`);
  },
  addVocabToCollection(vocabularyId, collectionId) {
    return ApiService.post(`vocab/collection/${collectionId}/vocabs`, {
      vocab_back_id: vocabularyId
    });
  },
  removeVocabFromCollection(vocabularyId, collectionId) {
    return ApiService.delete(`vocab/collection/${collectionId}/vocabs`, {
      vocab_back_id: vocabularyId
    });
  }
};
